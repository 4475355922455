<template>
  <v-container style="max-width: 1080px" class="v-contain">
    <div class="selebox">
      <div class="select"></div>
      <a href="https://twitter.com/fourier_labs" target="_blank"
        ><img src="@/assets/img/tabimg/twitter.svg" alt=""
      /></a>
      <a href="https://t.me/fourier_protocol" target="_blank"
        ><img src="@/assets/img/tabimg/telegram.svg" alt=""
      /></a>
    </div>
    <lottie
      id="lottie"
      :class="mobile ? 'isMobile' : ''"
      :options="{
        animationData: bgJson
      }"
      @animCreated="handleAnimation1"
    />
    <!-- <vue-particles class="particle" color="#dedede"></vue-particles> -->
    <!-- <div class="bg" :style="{ backgroundImage: `url(${bgImage})` }"></div> -->
    <!-- <div id="particles-js"></div> -->
    <Banner></Banner>
    <Features style="margin-top: 200px; position: relative"></Features>
    <Components style="margin-bottom: 100px; position: relative"></Components>
    <!-- <Edge style="margin-bottom:100px;"></Edge> -->
    <!-- <Roadmap style="margin-bottom:100px;position:relative;"></Roadmap> -->
    <Partner style="margin-bottom: 100px; position: relative"></Partner>
    <Background style="margin-bottom: 100px; position: relative"></Background>
  </v-container>
</template>

<script>
import bgJson from "../assets/json/bg_j.json";
import Banner from "../components/Banner.vue";
import Features from "../components/Features.vue";
import Components from "../components/Components.vue";
import Edge from "../components/Edge.vue";
// import Roadmap from '../components/Roadmap.vue'
import Partner from "../components/Partner.vue";
import Background from "../components/Background.vue";

import "particles.js";
import particlesConfig from "../components/particles/particlesjs-config.json";

export default {
  components: {
    Banner,
    Features,
    Components,
    Edge,
    // Roadmap,
    Partner,
    Background
  },
  name: "Home",
  data() {
    return {
      mobile: false,
      lottieControl: null,
      bgJson: bgJson,
      bgImage: require("../assets/img/background/Particle.svg")
    };
  },
  methods: {
    isMobile() {
      // 判断移动端还是pc端
      // isMobile方法
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return (
        flag ||
        (navigator.userAgent.toLowerCase().indexOf("macintosh") > -1 &&
          navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 2)
      );
    },
    handleAnimation1(anim) {
      this.lottieControl = anim;
    }
  },
  mounted() {
    this.mobile = this.isMobile();
    window.particlesJS("particles-js", particlesConfig);
  }
};
</script>

<style scoped lang="less">
#lottie {
  position: absolute;
  left: 0;
  top: 0%;
  width: auto !important;
  height: auto !important;

  &.isMobile {
    position: absolute;
    left: 0;
    top: 10%;
    width: auto !important;
    height: auto !important;
    transform: scaleY(1.4);
  }
}

.star {
  position: absolute;
  /* z-index: -1; */
  left: 0;
  top: 0;
  width: 100%;
}
.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 50%;
  background-size: 100% 100%;
}
#particles-js {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.particle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.selebox {
  position: fixed !important;
  right: 100px;
  top: 0;
  height: 450px;
  width: 60px;
  z-index: 1;
  a {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: rgb(55, 53, 90);
    position: relative;
    margin: 20px auto;
    img {
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
.select {
  width: 1px;
  height: 450px;
  margin: 0 auto;
  background-color: rgb(213, 213, 213);
}

@media screen and (max-width: 1200px) {
  .selebox {
    display: none;
  }
}
@media screen and (max-width: 1200px) {
  .bg {
    display: none;
  }
}
</style>

