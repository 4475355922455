<template>
  <div class="d-flex flex-column text-center">
    <!-- <div style="background:#fff;width:40px;height:2px;"></div> -->
    <div class="mt-2 text-h5 titleSemi">Value Proposition</div>
    <!-- <v-row class="mt-4">
      <v-col cols="6" md="4" lg="3" sm="6" v-for="(item,n) in list" :key="n">
        <v-card flat color="rgba(15,56,96,0.5)" class="pa-3" style="height:100%;">
          <div class="color-line"></div>
          <div>{{item.label}}</div>
        </v-card>
      </v-col>
    </v-row> -->
    <div class="bigbox titleRegualr">
      <div class="smallbox">
        <div class="imgbox">
          <img src="@/assets/font/Icon/eos-icons_performance.svg" alt="" />
        </div>
        <p class="whites">High Performance</p>
        <p>High Scalability, Sustainability,</p>
        <p>Interoperability, Security</p>
      </div>

      <div class="smallbox">
        <div class="imgbox">
          <img src="@/assets/font/Icon/bx_dollar-circle.svg" alt="" />
        </div>
        <p class="whites">Gas Fee</p>
        <p>Low Gas Fee</p>
      </div>

      <div class="smallbox">
        <div class="imgbox">
          <img src="@/assets/font/Icon/logos_ethereum.svg" alt="" />
        </div>
        <p class="whites">Ethereum</p>
        <p>Ethereum Compability</p>
      </div>

      <div class="smallbox">
        <div class="imgbox">
          <img src="@/assets/font/Icon/icon-experience.svg" alt="" />
        </div>
        <p class="whites">Experience</p>
        <p>Sleek User Experience for</p>
        <p>dApps Users</p>
      </div>

      <div class="smallbox">
        <div class="imgbox">
          <img src="@/assets/font/Icon/icon_cardano.svg" alt="" />
        </div>
        <p class="whites">Cardano</p>
        <p>First Mover Access</p>
        <p>to Cardano Ecosystem</p>
      </div>

      <div class="smallbox">
        <div class="imgbox">
          <img src="@/assets/font/Icon/contract-solid.svg" alt="" />
        </div>
        <p class="whites">Contract</p>
        <p>Familiar Code Language,</p>
        <p>Limited Contract Modification</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          label: "Scalable, Interoperable, Sustainable and Secure",
          desc: "Embedded in Cardano's academic peer reviewed, evidence based, verifiable secure, high throughput blockchain"
        },
        {
          label: "Familiar Coding Language ",
          desc: "No need to learn Haskell to deploy smart contract on Cardano. Solidity works in Fourier EVM environment"
        },
        {
          label: "Limited Contract Modification",
          desc: "Can use existing Solidity code library with little changes. Simply deploy"
        },
        {
          label: "First Mover to Cardano Ecosystem",
          desc: "Reach out to the Cardano community with millions of members including developers, individual users, enterprises"
        },
        {
          label: "Enhanced Developing Tools",
          desc: "Developer friendly SDK toolkit including visualisation, debugger, testing, plug-ins etc"
        },
        {
          label: "Sleek Experience for dApps Users",
          desc: "No need to swap token, switch wallet or change behaviour. Same token, wallet and process flow for user adoption"
        },
        {
          label: "Trustless Bridge",
          desc: "Moving tokens or crypto assets cross chain without frictions"
        },
        {
          label: "Ethereum Compatibility with Low Fee",
          desc: "Fourier provides Ethereum compatible environment to developer and user with low gas fee due to POS consensus in Cardano"
        }
      ]
    };
  }
};
</script>

<style scoped lang="less">
.text-h5 {
  font-size: 40px !important;
}
.color-line {
  height: 10px;
  width: 100%;
  border-radius: 5px;
  background: linear-gradient(to right, #02cafe, #4d3dc4);
  margin-bottom: 12px;
}
.align-center {
  background-color: rgb(16, 10, 61);
}
.bigbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
  .smallbox {
    padding-top: 20px;
    width: 300px;
    margin: 20px auto;
    .imgbox {
      width: 64px;
      height: 64px;
      margin: 0 auto;
      border-radius: 50px;
      background-color: rgb(40, 34, 80);
      position: relative;
      margin-bottom: 20px;
      img {
        // padding-bottom: 20px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }
    p {
      color: rgb(159, 159, 175);
      font-size: 17px;
    }
  }
}
.whites {
  color: white !important;
  font-size: 22px !important;
}
</style>

