<template>
  <div class="d-flex flex-column align-center text-center">
    <!-- <div style="background:#fff;width:40px;height:2px;"></div>
    <div class="mt-2 text-h5">COMPONENTS</div>
    <div class="text-subtitle-1" style="max-width:700px;margin-top:20px;">The Fourier Protocol consists of high performance EVM, enhanced software development kit and trustless cross chain bridge.</div>
    <img src="../assets/img/home/components.png" style="width:100%;margin-top:80px;"> -->
  </div>
</template>

<script>
export default {
  data(){
    return {
    }
  }
}
</script>

<style scoped>

</style>