<template>
  <v-footer color="rgba(0,0,0,0)" class="footer">
    <v-container class="py-0">
      <div class="footer-box">
        <div class="ft">
          <h1 class="titleSemi">Get In Touch</h1>

          <!-- <div class="al">
        <div class="a-link">
        <img src="@/assets/img/tabimg/twitter.svg" alt="">
      </div>
      
      <div class="a-link">
        <img src="@/assets/img/tabimg/Github.svg" alt="">
      </div>

      <div class="a-link">
        <img src="@/assets/img/tabimg/telegram.svg" alt="">
      </div>
      </div> -->

          <el-form :rules="emailRules" :model="emailForm" ref="formRef">
            <el-form-item prop="emailAdress">
              <el-input
                v-model="emailForm.emailAdress"
                placeholder="Email address"
              ></el-input>
            </el-form-item>

            <div class="center">
              <el-form-item prop="firstName">
                <el-input
                  v-model="emailForm.firstName"
                  placeholder="First name"
                  class="center-sml"
                ></el-input>
              </el-form-item>

              <el-form-item prop="lastName">
                <el-input
                  v-model="emailForm.lastName"
                  placeholder="Last name"
                  class="center-sml"
                ></el-input>
              </el-form-item>
            </div>

            <el-form-item prop="message">
              <el-input
                type="textarea"
                v-model="emailForm.message"
                placeholder="Message"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submit" class="send"
                >Send</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <p class="titleRegualr">© 2022. All rights reserved by Fourier labs</p>
      <!-- <div class="py-8 d-flex flex-row align-center">
        <div class="d-flex flex-row align-center">
          <span>©2022</span>
          <img src="../assets/img/header/logo.png" class="ml-1" style="height:30px;">
        </div>
        <div style="flex:1;text-align:right;">
          <v-btn target="_blank" fab depressed small class="pa-6" href="https://github.com/fourier-labs">
            <v-icon dense>
              mdi-github
            </v-icon>
          </v-btn>
          <v-btn target="_blank" fab depressed small class="pa-6 ml-4" href="https://twitter.com/fourier_labs">
            <v-icon dense>
              mdi-twitter
            </v-icon>
          </v-btn>
          <v-btn target="_blank" fab depressed small class="pa-6 ml-4" href="https://t.me/fourier_protocol">
            <v-avatar size="24">
              <v-img src="../assets/img/common/telegram.png"></v-img>
            </v-avatar>
          </v-btn>
        </div>
      </div> -->
    </v-container>
  </v-footer>
</template>

<script>
import { emailApi } from "@/api/index.js";
export default {
  data() {
    return {
      emailForm: {
        emailAdress: "", // 邮箱地址
        firstName: "", // firstName
        lastName: "", // lastName
        message: "" // 信息
      },
      emailRules: {
        emailAdress: [
          {
            required: true,
            message: "Please enter your email address",
            trigger: "blur"
          },
          {
            pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            message: "Please enter the correct email address",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    // 表单提交
    submit() {
      this.$refs.formRef.validate(async vlid => {
        if (!vlid) return false;
        const res = await emailApi(this.emailForm);
        console.log(res);
        if (res.status !== 200) return this.$message.error("unknown error");
        this.$message.success("success");
        this.emailForm = {};
      });
    }
  }
};
</script>

<style scoped lang="less">
.ft {
  padding-top: 70px;
}
.py-0 p {
  margin-top: 100px;
  text-align: center;
}
.footer {
  // background-image: url(../assets/img/background/footerbgi.jpg);
  background-size: cover;
}

.ft {
  width: 457px;
  margin: 0 auto;
  h1 {
    text-align: center;
    font-size: 40px;
    padding-bottom: 30px;
  }
  .al {
    width: 180px;
    display: flex;
    justify-content: space-evenly;
    margin: 50px auto;
    .a-link {
      width: 40px;
      height: 40px;
      position: relative;
      background-color: rgb(64, 63, 95);
      border-radius: 50%;
      img {
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}
.send {
  width: 100%;
  border-radius: 7px;
  height: 48px;
}
.center {
  width: 457px;
  display: flex;
  justify-content: space-between;
  .center-sml {
    width: 220px;
  }
}
/deep/.el-input__inner {
  border-radius: 7px;
  height: 58px;
}

/deep/.el-textarea__inner {
  height: 140px;
  border-radius: 7px;
}

@media screen and (max-width: 1200px) {
  .ft {
    width: 350px;
  }
  .center {
    width: 350px;
    .center-sml {
      width: 165px;
    }
  }
}
</style>

