import request from '@/request/index.js'

// 底部发送邮箱
export const emailApi = (data) => {
  // 因为是异步请求所以必须return 否则返回值为undefind
  return request({
    url: '/mail',
    method: 'POST',
    data: {
      text: 'firstName:' + '\t' + data.firstName + '\n' + 
            'lastName:' + '\t' +  data.lastName + '\n' + 
            'email address:' + '\t' + data.emailAdress + '\n' +  
            'your message:' + '\t' + data.message,
      subject: data.emailAdress, // 邮箱主题
      to: 'info@fourierlabs.io' // 发送到此邮箱
      // to: '1009093189@qq.com'
    }
  })
}
