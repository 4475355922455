<template>
  <v-app-bar
    :color="bgColor"
    dark
    flat
    fixed
    v-scroll="onScroll"
  >
    <v-container class="py-0 fill-height" style="z-index:1000;">
      <img style="height:55px;" src="../assets/img/header/logo.svg" />
      <v-spacer></v-spacer>
      
      <!-- <v-btn text class="action">
        Home
        <div class="circle"></div>
      </v-btn>
      <v-btn text>
        Contact
      </v-btn> -->
      
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    offsetTop: 0,
    bgColor: 'rgba(11,17,37,0)'
  }),
  methods:{
    onScroll(e){
      this.offsetTop = document.documentElement.scrollTop || document.body.scrollTop;
      this.bgColor = 'rgba(11,17,37,'+(this.offsetTop>200?0.8:this.offsetTop*0.8/200)+')'
      console.log(this.bgColor)
    }
  }
}
</script>

<style>
  .action {
    color: rgb(93,95,239) !important;
    position: relative;
  }

  .circle {
    position: absolute;
    width: 5px;
    height: 5px;
    background-color: rgb(93,95,239);
    border-radius: 50%;
    top: 20px;
  }
</style>