<template>
  <div class="d-flex flex-column align-center text-center">
    <div style="background:#fff;width:40px;height:2px;"></div>
    <div class="mt-2 text-h5">THE EDGE</div>
    <v-row style="margin-top:20px;width:100%;" no-gutters>
      <v-col offset="3" cols="3" class="pa-1">
        <div class="col-dark rounded-pill">Side Chain</div>
      </v-col>
      <v-col cols="3" class="pa-1">
        <div class="col-light rounded-pill">Layer 2</div>
      </v-col>
      <v-col cols="3" class="pa-1">
        <div class="col-dark rounded-pill">Fourier Protocol</div>
      </v-col>
    </v-row>
    <v-row style="width:100%;" v-for="(item,n) in items" :key="n" no-gutters>
      <v-col cols="3" class="pa-1">
        <div class="col-light rounded-pill">{{item[0]}}</div>
      </v-col>
      <v-col cols="3" class="pa-1">
        <div class="col-dark rounded-pill">{{item[1]}}</div>
      </v-col>
      <v-col cols="3" class="pa-1">
        <div class="col-light rounded-pill">{{item[2]}}</div>
      </v-col>
      <v-col cols="3" class="pa-1">
        <div class="col-dark rounded-pill">{{item[3]}}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data(){
    return {
      items: [
        ['Cardano Native','No','No','Yes'],
        ['Security','Low','Medium','High'],
        ['Scalability Potential','Low','Medium','High'],
        ['Maintenance Cost','Medium','High','Low'],
        ['Flex on Upgrade','Low','Medium','High'],
        ['Hierarchy','No','No','Yes']
      ]
    }
  }
}
</script>

<style scoped>
.col-dark{
  background: #225163;
}
.col-light{
  background: #0982be;
}
</style>