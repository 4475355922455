import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueParticles from "vue-particles";
import "animate.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import lottie from "vue-lottie";
Vue.component("lottie", lottie);
Vue.config.productionTip = false;

Vue.use(VueParticles);
Vue.use(ElementUI);

new Vue({
  router,
  store,
  vuetify,
  ElementUI,
  render: function (h) {
    return h(App);
  }
}).$mount("#app");

