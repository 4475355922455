<template>
  <div class="d-flex flex-column align-center text-center">
    <!-- <div style="background:#fff;width:40px;height:2px;"></div> -->
    <div class="mt-2 text-h5 titleSemi">Team</div>
    <div class="text-subtitle-1" style="max-width: 710px; margin-top: 20px">
      <p class="team-p titleRegualr">
        As the developer of Fourier Protocol, Fourier Labs was established in
        2021 by a team
      </p>
      <p class="team-p titleRegualr">
        of experienced engineers and entrepreneurs with a vision to support a
        decentralized,
      </p>
      <p class="team-p titleRegualr">
        permissionless and open economy, by empowering integrated blockchain
        ecosystems
      </p>
      <p class="team-p titleRegualr">
        and networks through technology innovation.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.text-subtitle-1 {
  font-family: "Roboto", sans-serif !important;
}
.team-p {
  font-size: 17px;
  padding: 0;
  margin: 0 !important;
  margin-top: 6px !important;
  color: #f6f6f6;
}
.mt-2 {
  font-size: 40px !important;
}
</style>

