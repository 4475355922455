<template>
  <div class="d-flex flex-column align-center text-center">
    <!-- <div style="background:#fff;width:40px;height:2px;"></div> -->
    <div class="mt-2 text-h5">Partners</div>
    <v-row style="max-width:700px;margin-top:50px;">
      <v-col cols="12" style="padding-left:20px;padding-right:20px;">
        <a href="https://emurgo.io/" style="z-index:2;position:relative;">
          <img style="width:100%;max-width:300px;" src="../assets/img/home/partner1.png">
        </a>
      </v-col>
      <!-- <v-col cols="6" style="padding-left:20px;padding-right:20px;">
        <a href="https://cardanofoundation.org/" style="z-index:2;position:relative;">
          <img style="width:100%;max-width:300px;" src="../assets/img/home/partner2.png">
        </a>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped lang="less">
.mt-2 {
  font-size: 40px !important;
}

</style>