<template>
  <v-app class="app">
    <Header></Header>
    <v-main>
      <router-view />
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  data: () => ({}),
  methods: {}
};
</script>

<style scoped lang="less">
@import url("~@/assets/font/css/font.css");

.app {
  background: rgb(5, 2, 49) !important;
  color: #fff;
}
/deep/ .titleSemi {
  font-family: "InterSemiBold" !important;
}
/deep/ .titleRegualr {
  font-family: "InterRegular" !important;
}
</style>

