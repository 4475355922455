<template>
  <div
    class="d-flex flex-column justify-center align-center text-center banner"
  >
    <div class="selet"></div>
    <div style="width: 100%">
      <img
        class="animate__animated animate__pulse"
        style="width: 90%; position: relative; z-index: 2"
        src="../assets/img/home/banner.svg"
      />
      <!-- <img style="width:100%;margin-top:-10px;" src="../assets/img/home/banner_line.png"> -->
    </div>
    <span
      class="text-subtitle-1 titleRegualr"
      style="margin-top: 20px; line-height: 2.5"
      >To establish a high performance Ethereum compatible dApp developing
      platform on Cardano</span
    >
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.banner {
  /* max-width:700px; */
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
}

/deep/.text-subtitle-1 {
  font-size: 21px !important;
}
</style>

